/* @import "normalize.css"; */

/* add the font link to HTML head */

/* $default-font-family: 'Lato', sans-serif; */

/* $default-font-family: 'Grandstander', cursive; */

/* for bespoke fonts */

/* ///////////////////  */

.f-roboto {
  font-family: 'Roboto', sans-serif;
}

.f-aleo {
  font-family: 'Aleo', serif;
}

.f-cardo {
  font-family: 'Cardo', serif;
}

.f-cormorant {
  font-family: 'Cormorant', serif;
}

.f-inter {
  font-family: 'Inter', sans-serif;
}

.f-josefin {
  font-family: 'Josefin Sans', sans-serif;
}

.f-lato {
  font-family: 'Lato', sans-serif;
}

.f-merriweather {
  font-family: 'Merriweather', serif;
}

.f-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.f-nunito {
  font-family: 'Nunito', sans-serif;
}

.f-open-sans {
  font-family: 'Open Sans', sans-serif;
}

.f-playfair {
  font-family: 'Playfair Display', serif;
}

.f-pt-sans {
  font-family: 'PT Sans', sans-serif;
}

.f-pt-serif {
  font-family: 'PT Serif', serif;
}

.f-quicksand {
  font-family: 'Quicksand', sans-serif;
}

.f-raleway {
  font-family: 'Raleway', sans-serif;
}

.f-work-sans {
  font-family: 'Work Sans', sans-serif;
}

.f-splash {
  font-family: 'Splash', cursive;
}

.f-tangerine {
  font-family: 'Tangerine', cursive;
}

.f-Be-vietnam {
  font-family: 'Be Vietnam Pro', sans-serif;
}

.f-poppins {
  font-family: 'Poppins', sans-serif;
}

/* /////////////////////////////  */

/* COLORS for Green Over Blue */

/* colours must be in rgb format to work with background colours */

/* the first 10 wouldn't work as background colours as they are in hex format */

.gob-white {
  color: 255, 255, 255 !important;
}

.gob-black {
  color: 0, 0, 0 !important;
}

.gob-grey-light-1 {
  color: 247, 247, 247 !important;
}

.gob-grey-light-2 {
  color: 238, 238, 238 !important;
}

.gob-grey-dark-3 {
  color: 51, 51, 51 !important;
}

.gob-primary {
  color: #267326 !important;
}

.gob-bg-primary {
  background-color: #267326;
}

.gob-secondary {
  color: #2b4973;
}

/* ////////////////////////////// */

/* COLORS for Visible */

/* $mainBlue: #2f5572;
$mainOrange: #d59541; */

/* /////////////////////////////  */

/* COLORS for Natours */

/* $color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000; */

/* ////////////////////////////  */

/* ////////////////////////////  */

/* COLORS for WestHill Music */

:root {
  --white: #ffffff;
  --black: #000000;

  --grey-pale: #f2f2f2;
  --grey-light: #bfbfbf;
  --grey: #808080;
  --grey-dark: #404040;

  --primary-pale: #e6f2ff;
  --primary-light: #99ccff;
  --primary: #be1212;
  /* --primary: pink; */
  --primary-dark: #07294b;

  --secondary-pale: #fff5e6;
  --secondary-light: #ffcc80;
  --secondary: #ff9900;
  --secondary-dark: #804d00;

  --tertiary-pale: #e6ffe6;
  --tertiary-light: #80ff80;
  --tertiary: #00cc00;
  --tertiary-dark: #2a771b;

  --highlight-pale: #ffebe6;
  --highlight-light: #66ff66;
  --highlight: #ff3300;
  --highlight-dark: #801a00;

  --light: #c5bfb9;
  --dark: rgb(68, 63, 61);
  --darker: rgb(37, 35, 34);
  --shadow: 0 1px 20px rgba(255, 127, 80, 0.8);
  --shadow-dark: 3px 3px 3px rgba(10, 10, 10, 0.2);
}

.col-white {
  color: var(--white) !important;
}

.col-black {
  color: var(--black);
}

.col-grey-pale {
  color: var(--primary-pale);
}

.col-grey-light {
  color: var(--primary-light);
}

.col-grey {
  color: var(--primary);
}

.col-grey-dark {
  color: var(--primary-dark);
}

.col-primary-pale {
  color: var(--primary-pale);
}

.col-primary-light {
  color: var(--primary-light);
}

.col-primary {
  color: var(--primary);
}

.col-primary-dark {
  color: var(--primary-dark);
}

.col-secondary {
  color: var(--secondary);
}

.col-secondary-pale {
  color: var(--secondary-pale);
}

.col-secondary-light {
  color: var(--secondary-light);
}

.col-secondary-dark {
  color: var(--secondary-dark);
}

.col-tertiary {
  color: var(--tertiary);
}

.col-tertiary-pale {
  color: var(--tertiary-pale);
}

.col-tertiary-light {
  color: var(--tertiary-light);
}

.col-tertiary-dark {
  color: var(--tertiary-dark);
}

.col-highlight {
  color: var(--highlight);
}

.col-highlight-pale {
  color: var(--highlight-pale);
}

.col-highlight-light {
  color: var(--highlight-light);
}

.col-highlight-dark {
  color: var(--highlight-dark);
}

.col-light {
  color: var(--light);
}

.col-dark {
  color: var(--dark);
}

.col-darker {
  color: var(--darker);
}

.bg-black {
  background-color: var(--black);
}

.bg-white {
  background-color: var(--white);
}

.bg-grey-pale {
  background-color: var(--grey-pale);
}

.bg-grey-light {
  background-color: var(--grey-light);
}

.bg-grey {
  background-color: var(--grey);
}

.bg-grey-dark {
  background-color: var(--grey-dark);
}

.bg-primary-pale {
  background-color: var(--primary-pale);
}

.bg-primary-light {
  background-color: var(--primary-light);
}

.bg-primary {
  background-color: var(--primary);
}

.bg-primary-dark {
  background-color: var(--primary-dark);
}

.bg-secondary-pale {
  background-color: var(--secondary-pale);
}

.bg-secondary-light {
  background-color: var(--secondary-light);
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-secondary-dark {
  background-color: var(--secondary-dark);
}

.bg-tertiary-pale {
  background-color: var(--tertiary-pale);
}

.bg-tertiary-light {
  background-color: var(--tertiary-light);
}

.bg-tertiary {
  background-color: var(--tertiary);
}

.bg-tertiary-dark {
  background-color: var(--tertiary-dark);
}

.bg-highlight-pale {
  background-color: var(--highlight-pale);
}

.bg-highlight-light {
  background-color: var(--highlight-light);
}

.bg-highlight {
  background-color: var(--highlight);
}

.bg-highlight-dark {
  background-color: var(--highlight-dark);
}

.bg-light {
  background-color: var(--light);
}

.bg-dark {
  background-color: var(--dark);
}

.bg-darker {
  background-color: var(--darker);
}

.bg-rich {
  background-color: #606c38;
}

.template-background {
  background-color: #00ff00;
}

.template-text {
  color: #ff00ff;
}

.mainOrange {
  color: #d59541;
}

/* //////////////////////////////////////////  */

/* Mixins for break points  */

/* //////////////////////////////////////////  */

/* Flexbox container for grid */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 800px) {

.container {
    /* @media (min-width: 1200px) { */
    flex-direction: row;
    flex-wrap: wrap
}
  }

.halves-box {
  flex: 0 1 49%;
}

.thirds-box {
  flex: 0 1 32%;
  margin: 1rem 2rem;
}

@media (min-width: 800px) {

.thirds-box {
    /* @media (min-width: 1200px) { */
    margin: 0
}
  }

.thirds-box-1 {
  margin: 0;
  flex: 0 1 34%;
}

.thirds-box-2 {
  margin: 0;
  flex: 0 1 64%;
}

.quaters-box {
  flex: 0 1 23%;
  margin: 2rem 0rem;
  /* &:last-child {
    margin-right: 0;
  } */
}

@media (min-width: 800px) {

.quaters-box {
    /* @media (min-width: 1200px) { */
    margin: 0
}
  }

/* ////////////////////////////  */

/* Pattern styles */

/* Not for general usage */

.col-container {
  display: flex;
  flex-direction: column;

  /* gap will be show vertically */
  /* use margin instead below to stop this */
}

/* gap: 2rem; */

@media (min-width: 530px) {

.col-container {
    /* @media (min-width: 620px) {  */
    flex-direction: row
}
  }

.col-container--center-content > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.left-half {
  background-color: #ff9e2c;
  flex: 1 1 auto;
  padding: 1rem;
}

@media (min-width: 530px) {

.left-half {
    /* @media (min-width: 620px) {  */
    /* mentioned above */
    margin-right: 0.75rem
}
  }

.right-half {
  background-color: #b6701e;
  flex: 1 1 auto;
  padding: 1rem;
}

@media (min-width: 530px) {

.right-half {
    /* @media (min-width: 620px) {  */
    /* mentioned above */
    margin-left: 0.75rem
}
  }

/* /////////////////////  */

/*  50-50  grid container */

.col-container-grid {
  display: grid;
}

/* gap: 1rem; */

.col-container-grid__left-half {
    background: #008b8b;
  }

/* grid-column: auto;  use unset ot auto*/

@media (min-width: 530px) {

.col-container-grid__left-half {
    /* @media (min-width: 620px) {  */
      grid-column: 1;
  }
  }

.col-container-grid__left-half {
    margin-right: 0.75rem;
}

.col-container-grid__right-half {
    background: #556b2f;
  }

/* grid-column: unset; */

@media (min-width: 530px) {

.col-container-grid__right-half {
    /* @media (min-width: 620px) {  */
      grid-column: 2;
  }
  }

.col-container-grid__right-half {
    margin-left: 0.75rem;
}

.col-container-grid__article {
    position: relative;
    top: 50%;
    text-align: center;
    transform: translate(0, -50%);
    padding: 1rem;
  }

/* ////////////////////////////////////////////////////  */

/* provide responsive padding for each section in a page*/

/* ////////////////////////////////////////////////////  */

.wrapper {
  overflow: hidden;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  max-width: 1236px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper--large {
    max-width: 1400px;
  }

.wrapper--medium {
    max-width: 976px;
  }

.wrapper--narrow,
  .wrapper--small {
    max-width: 470px;
  }

.wrapper--full,
  .wrapper--xlarge {
    max-width: none;
  }

.wrapper--no-padding-until-small {
    padding-left: 0;
    padding-right: 0;
  }

@media (min-width: 530px) {

.wrapper--no-padding-until-small {
    /* @media (min-width: 620px) {  */
      padding-left: 18px;
      padding-right: 18px
  }
  }

.wrapper--no-padding-until-medium {
    padding-left: 0;
    padding-right: 0;
  }

@media (min-width: 800px) {

.wrapper--no-padding-until-medium {
    /* @media (min-width: 1200px) { */
      padding-left: 18px;
      padding-right: 18px
  }
  }

.wrapper--no-padding-until-large {
    padding-left: 0;
    padding-right: 0;
  }

@media (min-width: 1250px) {

.wrapper--no-padding-until-large {
      padding-left: 18px;
      padding-right: 18px
  }
  }

.wrapper .wrapper {
    /* Any wrapper nested in another wrapper to have no padding  */
    padding-left: 0;
    padding-right: 0;
  }

.wrapper--b-margin {
    margin-bottom: 1.5rem;
  }

@media (min-width: 530px) {

.wrapper--b-margin {
    /* @media (min-width: 620px) {  */
      margin-bottom: 3.875rem
  }
  }

/* /////////////////////////////////////////// */

/* Blog grid wrapper - RJ Militaria articles  */

.blog-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 5%;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-gap: 20px;
}

.blog-grid__box {
  border: 1px solid var(--primary);
  padding: 1rem;
  margin: 1rem;
  display: grid;
  align-items: center;
  justify-content: center;
}

.bg--dark {
  background-color: var(--dark);
}

/* //////////////////////////////////////////// */

/*  Add top and bottom padding for each section */

.page-section {
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
}

@media (min-width: 800px) {

.page-section {
    /* @media (min-width: 1200px) { */
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
}
  }

.page-section--more-b-margin {
    margin-bottom: 7rem;
  }

@media (min-width: 1250px) {

.page-section--more-b-margin {
      padding-bottom: 9rem !important
  }
  }

.page-section--no-b-padding-until-medium {
    padding-bottom: 0;
  }

@media (min-width: 800px) {

.page-section--no-b-padding-until-medium {
    /* @media (min-width: 1200px) { */
      padding-bottom: 4.5rem
  }
  }

.page-section--no-b-padding-until-large {
    padding-bottom: 0;
  }

@media (min-width: 1250px) {

.page-section--no-b-padding-until-large {
      padding-bottom: 4.5rem
  }
  }

.page-section--after-skew {
    padding: 0;
    margin-top: -10rem;
  }

@media (min-width: 800px) {

.page-section--after-skew {
    /* @media (min-width: 1200px) { */
      margin-top: 0
  }
  }

.page-section--blue {
    background-color: #2f5572;
    color: #fff;
  }

.page-section--testimonials {
    background-color: #e0e6ef;
  }

@media (min-width: 530px) {

.page-section--testimonials {
    /* @media (min-width: 620px) {  */
      background: url('assets/images/testimonials-bg.jpg') top center no-repeat;
      background-size: cover
  }
  }

.page-section--vis-testimonials {
    background-color: #e0e6ef;
  }

@media (min-width: 530px) {

.page-section--vis-testimonials {
    /* @media (min-width: 620px) {  */
      background: url('assets/images/portfolio/country-2.jpg') top center
        no-repeat;
      background-size: cover
  }
  }

/* //////////////////////////////////////////// */

/*  Use for text boxes inside a grid */

/* //////////////////////////////////////////// */

.generic-content-container p {
    font-weight: 300;
    line-height: 1.65;
    margin: 0 0 1.8rem 0;
  }

@media (min-width: 530px) {

.generic-content-container p {
    /* @media (min-width: 620px) {  */
      font-size: 1.65rem
  }
  }

.generic-content-container p a {
    font-weight: 700;
    color: #d59541;
  }

/* ////////////////////////// */

/*  picture gallery options   */

/* ///////////////////////// */

.r-hero {
  border-bottom: 10px solid #2f5572;
  /* position: relative; */
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.r-hero__image {
    opacity: 0.5;
    grid-column: 1 / 3;
    grid-row: 1;
  }

.r-hero__image img {
      display: block;
      width: 100%;
    }

.r-hero__text-content-c {
    grid-column: 1 / 3;
    grid-row: 1;
    align-self: center;
    padding-top: 60px;
    text-align: center;
    /* position: absolute; top: 50%;  transform: translateY(-50%);  left: 0;   width: 100%; */
  }

.r-hero__text-content-l {
    grid-column: 1 / 2;
    grid-row: 1;
    align-self: center;
    padding-top: 60px;
    text-align: center;
  }

.r-hero__text-content-r {
    grid-column: 2 / 3;
    grid-row: 1;
    align-self: center;
    padding-top: 60px;
    text-align: center;
  }

.r-hero__title {
    font-weight: 300;
    color: #2f5572;
    margin: 0;
    font-size: 4.2rem;
  }

.r-hero__subtitle {
    font-weight: 300;
    color: #2f5572 !important;
    margin: 0;
    font-size: 3.2rem !important;
  }

.r-hero__description {
    font-weight: 300;
    color: #2f5572;
    font-size: 2.4rem;
    margin: 0;
  }

.r-hero__subtitle {
    color: #fff;
    font-size: 2.1rem;
    font-weight: 100;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    max-width: 42rem;
    margin: 0 auto;
  }

.r-hero__description--text-main-color {
    color: #2f5572;
  }

.abs-hero {
  border-bottom: 10px solid #2f5572;
  position: relative;
}

.abs-hero__image {
    display: block;
    /* opacity: 0.2; */
  }

.abs-hero__text-content {
    padding-top: 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    text-align: center;
  }

.abs-hero__title {
    font-weight: 300;
    color: #2f5572;
    margin: 0;
    font-size: 2.4rem;
  }

@media (min-width: 530px) {

.abs-hero__title {
    /* @media (min-width: 620px) {  */
      font-size: 4.8rem
  }
  }

.abs-hero__subtitle {
    font-weight: 300;
    color: #2f5572;
    font-size: 1.5rem;
    margin: 0;
  }

@media (min-width: 530px) {

.abs-hero__subtitle {
    /* @media (min-width: 620px) {  */
      font-size: 2.9rem
  }
  }

.abs-hero__description {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 100;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }

@media (min-width: 530px) {

.abs-hero__description {
    /* @media (min-width: 620px) {  */
      font-size: 1.875rem
  }
  }

/* //////////////////////////////////////////  */

/* utility classes - probably not now needed */

/*

.box {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 5%; }

.box-1 {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15%; }

.box-2 {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 23%; }

.break {
  min-width: 100%;
  margin: 0 auto;
  padding: 0 23%;
  min-height: 5vh; }


@media only screen and (max-width: 600px) {
  .box-1, .box-2 {
    padding: 0 5%; } 
}

/* //////////////////////////////////////////  */

/*  Overall container - probably now not needed */

/* .container:before,
.container:after,
.group:before,
.group:after {
  content: " ";
  display: table; }

.container:after,
.group:after {
  clear: both; } */

/* .container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 5%; 
} */

/* #main-container {
  max-width: 1500px; 
  margin: 0 auto;
  }
  
  #main-container > div, #main-container > section {
    padding-bottom: 3%;
    padding-top: 3%; 
  } */

/* /////////////// halves grid layout //////////////   */

/*
 .halves { 
    display:grid; 
    grid-template-columns: 1fr;
    justify-content: center; 
    align-items: center; 
    grid-gap: 3rem; 
    /* padding: 3rem 0; */

/* margin-top: 2rem;  */

/* grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));*/

/* font-family: 'Oxygen', sans-serif; */

/*
    &--no-gutter { grid-gap: 0; }
    &--gutter-large { 
      grid-gap: 1.5rem; 

      @mixin atSmall {
        grid-gap: 6.5rem;
      }
    }

    &--t-padding {
      padding-top: 15rem;
  
      @mixin atSmall {
        padding-top: 10rem;
      }
    }
}

@media only screen and (min-width: 800px) {
    .halves {  
          grid-template-columns: repeat(2, 1fr);
    }
} 
*/

/* ////////////////////////////////////////////////////  */

/* Grid thirds */

/*
.thirds { 
  display:grid; 
  grid-template-columns: 1fr;
  justify-content: center; 
  align-items: center; 
  grid-gap: 3rem; 
  /* padding: 3rem 0; */

/* margin-top: 2rem;  */

/* grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));*/

/* font-family: 'Oxygen', sans-serif; */

/*
  &--no-gutter { grid-gap: 0; }

  &--gutter-large { 
    grid-gap: 1.5rem; 

    @mixin atSmall {
      grid-gap: 6.5rem;
    }
  }

  &--t-padding {
    padding-top: 15rem;

    @mixin atSmall {
      padding-top: 10rem;
    }
  }

}

@media only screen and (min-width: 800px) {
  .thirds {  
        grid-template-columns: repeat(3, 1fr);
        &--col-1 { grid-column: 1/2; }
        &--col-2 { grid-column: 2/3; }
        &--col-3 { grid-column: 3/4; }
        &--col-12 { grid-column: 1/3; }
        &--col-23 { grid-column: 2/4; }
  } 
}
*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 56%;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 72%;
  }
}

/*
https://type-scale.com/
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
*/

body {
  /* font-family: 'Roboto', sans-serif;   */
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 2rem;
  color: #333;
  /* color: var(--secondary); */
  overflow-x: hidden; /*added for omni menu */
}

h1 {
  font-size: 4rem;
  line-height: 1.4;
  font-weight: 300;
  padding: 1rem 0;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.1;
  font-weight: 300;
  padding: 1rem 0;
}

h3 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 300;
  padding: 1rem 0;
}

h4 {
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 300;
  padding: 1rem 0;
}

h5 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 300;
  padding: 1rem 0;
}

h6 {
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 300;
  padding: 1rem 0;
}

p {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
  padding: 0.5em 0;
}

a {
  /* color: $mainOrange;
  /* color: var(--highlight); */
  color: #2b4973;
  font-weight: normal;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

dl {
  font-size: 1.8rem;
  line-height: 1.6;
  padding: 0.5em 0;
}

ol li {
  /* list-style-type: decimal; or 'decimal-leading-zero', 'upper-alpha', 'lower-alpha'... */
  list-style-position: outside; /* or 'inside' */
  margin-left: 1em;
  line-height: 1.6;
}

ul li {
  /* list-style-type: decimal; or 'decimal-leading-zero', 'upper-alpha', 'lower-alpha'... */
  list-style-position: outside; /* or 'inside' */
  margin-left: 1em; /* gives 'room' for the numbers to appear in */
  line-height: 1.6;
}

label {
  font-size: 1.5rem;
}

::-moz-selection {
  background-color: #55c57a;
  color: #fff;
}

::selection {
  background-color: #55c57a;
  color: #fff;
}

/* //////////////////////////////////// */

/*               Utility                */

/* //////////////////////////////////// */

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 530px) {

.center-row {
    /* @media (min-width: 620px) {  */
    font-size: 2.1rem;
    flex-direction: row;
    gap: 2rem
}
  }

.center-block {
  margin: 0 auto !important;
}

.center-image {
  display: block !important;
  margin: 0 auto !important;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.image-100-per,
.img-100,
.img-100p {
  width: 100%;
}

.center-text {
  text-align: center;
}

/* .hide {display: none; } */

.hide {
  opacity: 0;
  visibility: hidden;
}

.logos {
  display: flex;
}

.caps {
  text-transform: uppercase;
}

.no-pad {
  padding: 0 !important;
}

.pad--small {
  padding: 1.5rem !important;
}

.padb--small {
  padding-bottom: 1.5rem !important;
}

.padt--small {
  padding-top: 1.5rem !important;
}

.pads--small {
  padding: 0 1.5rem !important;
}

.pad--med {
  padding: 4rem !important;
}

.padb--medium {
  padding-bottom: 4rem !important;
}

.padt--medium {
  padding-top: 4rem !important;
}

.pads--medium {
  padding: 0 4rem !important;
}

.pad--large {
  padding: 8rem !important;
}

.pads--large {
  padding: 8rem !important;
}

.padb--large {
  padding-bottom: 8rem !important;
}

.padt--large {
  padding-top: 8rem !important;
}

.pad--xlarge {
  padding: 10rem 0 !important;
}

.padtb--large {
  padding: 8rem 0 !important;
}

.padtb--xlarge {
  padding: 10rem 0 !important;
}

.padt--xlarge {
  padding-top: 10rem !important;
}

.no-margin {
  margin: 0;
}

.margin--small {
  margin: 2rem !important;
}

.marginb--small {
  margin-bottom: 2rem !important;
}

.margint--small {
  margin-top: 2rem !important;
}

.margin--medium {
  margin: 4rem !important;
}

.marginb--medium {
  margin-bottom: 4rem !important;
}

.margint--medium {
  margin-top: 4rem !important;
}

.margin--large {
  margin: 8rem !important;
}

.marginb--large {
  margin-bottom: 8rem !important;
}

.margint--large {
  margin-top: 8rem !important;
}

.margint--super {
  margin-top: 15rem !important;
}

.t--thin {
  font-weight: 100;
  font-family: 'Roboto', sans-serif;
}

.t--medium {
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.t--thick {
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}

.italic {
  font-style: italic !important;
}

.tp--small {
  font-size: 1.5rem;
}

.tp--medium {
  font-size: 1.8rem;
}

.tp--large {
  font-size: 2.1rem;
}

.tp--xlarge {
  font-size: 2.4rem;
}

.banner {
  font-size: 5rem;
  line-height: 1;
}

/* .headline {  margin: 0;  font-family: 'Roboto Condensed', sans-serif;  font-weight: 300; }
.headline--medium {  font-size: 3.5rem;  margin-bottom: .75rem; }
.headline--small {    font-size: 3.0rem;    margin-bottom: .75rem; } */

.subhead::first-letter {
  font-size: 150%;
  color: var(--primary);
}

.border-black {
  border: var(--black);
}

.border-white {
  border: var(--white);
}

.border-grey {
  border: var(--grey);
}

.border-primary {
  border: var(--primary);
}

.border-secondary {
  border: var(--secondary);
}

.border-tertiary {
  border: var(--tertiary);
}

.border-highlight {
  border: var(--highlight);
}

.border-fine {
  border: 1px solid;
}

.border-thin {
  border: 2px solid;
}

.border-medium {
  border: 3px solid;
}

.border-thick {
  border: 4px solid;
}

blockquote {
  font-style: italic;
  padding: 0.5em 0;
}

/* **************** */

/* Horizontal Rules */

hr.black {
  border: 1.5px solid var(--black);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}

hr.white {
  border: 1.5px solid var(--white);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}

hr.grey {
  border: 1.5px solid var(--grey);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}

hr.primary {
  border: 1.5px solid var(--primary);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}

hr.secondary {
  border: 1.5px solid var(--secondary);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}

hr.tertiary {
  border: 1.5px solid var(--tertiary);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}

hr.highlight {
  border: 1.5px solid var(--highlight);
  border-radius: 3px;
  margin: 2rem auto;
  max-width: 700px;
}

/* //////////////////////////////////// */

/*               buttons                */

/* //////////////////////////////////// */

.btn {
  display: inline-block; /*   as this has padding  */
  text-decoration: none;
  margin-top: 3.2px;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
  color: var(--white);
  font-size: 1.7rem;
  padding: 0.8rem 1.2rem;
  border: none;
  outline: none;
  background: var(--primary);
}

.btn--orange {
    background: var(--secondary);
  }

.btn--orange:hover {
      background: var(--secondary-dark) !important;
    }

.btn--red {
    background: #b94141;
  }

.btn--red:hover {
      background: #be1212 !important;
    }

.btn--large {
    font-size: 1.8rem;
    padding: 1.4rem 1.2rem;
  }

/* padding: 1.1rem 1.9rem; */

@media (min-width: 530px) {

.btn--large {
    /* @media (min-width: 620px) {  */
      font-size: 2.1rem
      /* font-size: 1.25rem; */
  }
  }

.btn:hover,
.btn:active {
  background-color: var(--primary-dark);
}

.btn--primary {
  background-color: var(--primary);
}

/* ********************************** */

.btn-1,
  .btn-1:link,
  .btn-1:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    font-size: 1.6rem;
    border: none;
    cursor: pointer;
  }

.btn-1:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(#000, 0.2);
  }

/* .btn:hover::after  - increase the size of the pseudo element*/

.btn-1:hover::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }

.btn-1:active,
  .btn-1:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(#000, 0.2);
  }

.btn-1--white {
    background-color: #fff;
    color: #777;
  }

.btn-1--white::after {
      background-color: #fff;
    }

.btn-1--green {
    background-color: #55c57a;
    color: #fff;
  }

.btn-1--green::after {
      background-color: #55c57a;
    }

.btn-1::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

.btn-1--animated {
    -webkit-animation: moveInBottom 0.5s ease-out 0.75s;
            animation: moveInBottom 0.5s ease-out 0.75s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }

.btn-1-text:link,
  .btn-1-text:visited {
    font-size: 1.6rem;
    color: #55c57a;
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid #55c57a;
    padding: 0.5rem;
    transition: all 0.2s;
  }

.btn-1-text:hover {
    background-color: #55c57a;
    color: #fff;
    box-shadow: 0 1rem 2rem rgba(#000, 0.15);
    transform: translateY(-2px);
  }

.btn-1-text:active {
    box-shadow: 0 0.5rem 1rem rgba(#000, 0.15);
    transform: translateY(0);
  }

/* //////////////////////////////////// */

/*               icons                  */

/* //////////////////////////////////// */

.icon {
  height: 6.5rem;
  width: 6.5rem;
}

.icon--orange {
    fill: #d59541;
  }

/* //////////////////////////////////// */

/*         social media icons           */

/* //////////////////////////////////// */

.social-icons {
  text-align: center;
}

.social-icons__icon {
    background-color: #d59541;
    display: inline-block;
    width: 33%;
    height: 72px;
    margin: 0 5px 5px 5px;
    position: relative;
  }

@media (min-width: 530px) {

.social-icons__icon {
    /* @media (min-width: 620px) {  */
      width: 72px;
  }
  }

.social-icons__icon img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

/* //////////////////////////////////// */

/*               Video                  */

/* //////////////////////////////////// */

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.3;
  overflow: hidden;
}

.bg-video__content {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

/* //////////////////////////////////// */

/*               Animations             */

/* //////////////////////////////////// */

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/* ///////////////////   */

.gallery {
  display: grid;
}

.gallery__item {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; /* this must gave the width and height set  */
    display: block;
  }

.gallery__img {
    width: 100%;
  }

.gallery > * {
  }

/* 
@import 'modules/_large-hero';
@import 'modules/_page-section';
@import 'modules/_headline';
@import 'modules/_row';
@import 'modules/_section-title';
@import 'modules/_feature-item';
@import 'modules/_testimonial';
@import 'modules/_site-footer';
@import 'modules/_site-header';
@import 'modules/_primary-nav';
@import 'modules/_reveal-item'; */

/* @import 'modules/_modal'; */

/* @import 'modules/_client-area';
@import '../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';
@import 'modules/_visible-components'; */

/* @import 'visible'; */

/* @import 'modules/_jennys-hair'; */

/* @import 'modules/_rjm'; */

/* @import 'chairs/_chairs'; */

/* /////////////////////////// */

/* Green over blue OR Omnifood */

/* /////////////////////////// */

/* @import 'omnifood/_omnifood'; */

/* @import 'omnifood/_omnifood_general'; */

/* 
@import 'greenOverBlue/_gobHome';
@import 'greenOverBlue/_gobHeader';
@import 'greenOverBlue/_gobNavigation';
@import 'greenOverBlue/_gobHeroSlider';
@import 'greenOverBlue/_gobCarousel.css';

/* gob2 */

/* @import 'greenOverBlue/_gb.css'; */

/* ////////////////////////////////// */

/* West Hill Music */

/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6

- Letter spacing
-0.5px
0.75px

--- 02 COLORS


- Primary: #e67e22
- Tints:
#fdf2e9
#fae5d3
#eb984e

- Shades: 
#cf711f
#45260a

- Accents:
- Greys

#888
#767676 (lightest grey allowed on #fff)
#6f6f6f (lightest grey allowed on #fdf2e9)
#555
#333

--- 05 SHADOWS

0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);

--- 06 BORDER-RADIUS

Default: 9px
Medium: 11px

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

/* ******************* */

/* GENERAL COMPONENTS  */

/*  with WH overides   */

/* ******************* */

/* Webfont: Glowstore-Regular */

@font-face {
    font-family: 'Glowstore';
    src: url('./assets/fonts/Glowstore.eot'); /* IE9 Compat Modes */
    src: url('./assets/fonts/Glowstore.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('./assets/fonts/Glowstore.woff') format('woff'),
      /* Modern Browsers */ url('./assets/fonts/Glowstore.woff2')
        format('woff2'),
      /* Modern Browsers */ url('./assets/fonts/Glowstore.ttf')
        format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }

@font-face {
    font-family: 'Oliver';
    src: url('./assets/fonts/Oliver-Thin.eot');
    src: local('./assets/fonts/Oliver Thin'), local('Oliver-Thin'),
      url('./assets/fonts/Oliver-Thin.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Oliver-Thin.woff2') format('woff2'),
      url('./assets/fonts/Oliver-Thin.woff') format('woff'),
      url('./assets/fonts/Oliver-Thin.ttf') format('truetype'),
      url('./assets/fonts/Oliver-Thin.svg#Oliver-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
  }

.wh {
  font-family: 'Lato', sans-serif;
  color: #555;
  line-height: 1;
  font-weight: 400;
  --color-1: #336832;
  --color-2: #336832;
  --color-3: #8f241c;
  --color-4: #a72b22;
  --color-5: #b8ad96;

  --color-5a: #dbd6ca;
  --color-6: #48514e;
  --color-bg: #fdf2e9;
  --color-grey: #333;
  --color-grey-lt: #555;
  --gradient-primary: linear-gradient(to top left, #283618, #90a54d);
}

/* --color-4: #bc6c25; */

.wh .page-section--green {
    background-color: var(--color-2);
    color: #fff;
  }

/* --color-3: #dda15e; */

.wh .section-title--green {
    color: var(--color-grey);
  }

/* --color-2: #606c38; */

.wh .icon--red {
    fill: var(--color-);
  }

/* --color-1: #283618; */

.wh .heading-tertiary {
    font-size: 1.8rem;
  }

/* font-family: 'Glowstore', sans-serif; */

.wh .wh-background-color {
    background-color: var(--color-bg);
  }

/* font-family: 'Oliver', sans-serif; */

.wh .wh-background-color-white {
    background-color: #fff;
  }

/* font-family: 'Be Vietnam Pro', sans-serif; */

.wh .wh-background-color-beige {
    background-color: var(--color-5);
  }

/* font-family: 'Rubik', sans-serif; */

.wh .wh-background-color-beige-lt {
    background-color: var(--color-5a);
  }

.wh .wh-beige-lt {
    color: var(--color-5);
  }

.wh .wh-gold {
    color: #bc6c25;
  }

.wh .wh-red {
    color: var(--color-4);
  }

.wh .wh-light-green {
    color: var(--color-2);
  }

.wh .wh-dark-green {
    color: var(--color-1) !important;
  }

.wh .wh-bg-light-green {
    background-color: var(--color-2);
  }

.wh .wh-bg-dark-green {
    background-color: var(--color-1);
  }

.wh .wh-beige {
    color: var(--color-6);
  }

.wh h1 {
    font-size: 3.4rem;
    line-height: 1.2;
    font-weight: 300;
    padding: 1rem 0;
  }

.wh h2 {
    font-size: 2.8rem;
    line-height: 1.4;
    font-weight: 300;
    padding: 1rem 0;
  }

.wh h3 {
    color: inherit;
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight: 300;
    padding: 1rem 0;
  }

.wh p {
    /* font-size: 1.8rem; */
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.3;
    padding: 0.5em 0;
    color: inherit;
  }

.arrow-font {
  color: #283618 !important;
  font-size: 2.2rem;
}

.offer {
  display: block;
  margin-bottom: 2.5rem;
  font-weight: bold;
}

.offer-font {
  text-align: center !important;
  padding: 4rem 1rem !important;
}

.margin-right--sm {
  margin-right: 1.6rem !important;
}

.hero__img {
  width: 90%;
  display: block;
  margin: 0 auto;
}

@media (min-width: 800px) {

.hero__img {
    /* @media (min-width: 1200px) { */
    width: 100%
}
  }

.wh_heading-primary,
.wh-heading-secondary {
  font-weight: 700;
  color: var(--color-grey);
  letter-spacing: -0.5px;
}

.wh_heading-primary {
  font-size: 4.4rem !important;
  line-height: 1.2;
  margin-bottom: 1.2rem;
}

@media (min-width: 1500px) {

.wh_heading-primary {
    font-size: 5.2rem
}
  }

.wh-heading-secondary {
  font-size: 3.6rem;
  line-height: 1.05;
}

@media (min-width: 1250px) {

.wh-heading-secondary {
    font-size: 4.4rem
}
  }

@media (min-width: 800px) {

.wh-heading-secondary {
    /* @media (min-width: 1200px) { */
    font-size: 4rem
}
  }

.wh_ul-style {
  /* list-style-type: square; */
  display: flex;
  gap: 0 3rem;
  font-size: 3.4rem;
}

.wh_grid {
  display: grid;
  font-size: 2.4rem;
}

.wh_btn,
.wh_btn:link,
.wh_btn:visited {
  display: inline-block; /*   as this has padding  */
  text-decoration: none;
  margin: 3.2px;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
  color: var(--white);
  font-size: 2.2rem;
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  background: var(--color-3);
  transition: all 0.3s;
}

.wh_btn--large, .wh_btn:link--large, .wh_btn:visited--large {
    font-size: 1.8rem;
    padding: 1.4rem 1.2rem;
  }

/* padding: 1.1rem 1.9rem; */

@media (min-width: 530px) {

.wh_btn--large, .wh_btn:link--large, .wh_btn:visited--large {
    /* @media (min-width: 620px) {  */
      font-size: 2.1rem
      /* font-size: 1.25rem; */
  }
  }

.wh-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* border: 3px solid green; */
}

.wh_btn-full:link,
.wh_btn-full:visited {
  background-color: var(--color-3);
  color: white;
}

.wh_btn-full:hover,
.wh_btn-full:active {
  background-color: var(--color-4);
}

.wh_btn-outline:link,
.wh_btn-outline:visited {
  background-color: var(--color-2);
  /* color: #555; */
  color: #fdf2e9;
}

.wh_btn-outline:hover,
.wh_btn-outline:active {
  background-color: var(--color-1);
  /* border will make the button jump */
  /* trick to add border inside */
  /* box-shadow: inset 0 0 0 3px #fff; */
}

.wh-main-title {
  /* font-family: 'Glowstore', sans-serif; */
  font-size: 3.6rem !important;
  color: var(--color-grey) !important;
  /* font-weight: 300; */
  font-weight: bold !important;
  text-align: center;
}

@media (min-width: 530px) {

.wh-main-title {
    /* @media (min-width: 620px) {  */
    font-size: 4rem
}
  }

.wh-section-title {
  /* font-family: 'Glowstore', sans-serif; */
  font-size: 3.2rem !important;
  color: var(--color-grey) !important;
  /* font-weight: 300; */
  font-weight: bold !important;
  text-align: center;
}

@media (min-width: 530px) {

.wh-section-title {
    /* @media (min-width: 620px) {  */
    font-size: 3.8rem
}
  }

.wh-text-strong {
  font-weight: 600 !important;
  text-transform: capitalize;
}

/* ******************* */

/* HEADER SECTION      */

/* ******************* */

.wh_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fdf2e9; */
  background-color: var(--color-2);

  /* define height instead of vertical padding */
  /* as we want header to be sticky */
  height: 9.6rem;
  padding: 0 3.2rem;
  position: relative;
}

@media (min-width: 1250px) {

.wh_header {
    padding: 0 4.8rem
}
  }

/* ********************************  */

/*      NAVIGATON SECTION            */

/* ********************************  */

.hamburger {
  display: none;
  width: 35px;
  /* height: 35px; */
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  outline: none;
  border: none;
}

.hamburger .bar,
.hamburger::before,
.hamburger::after {
  content: '';
  display: block;
  width: 100%;
  height: 5px;
  /* background-color: var(--color-grey); */
  background-color: var(--color-5);
  margin: 6px 6px;
  transition: all 0.4s;
}

.hamburger.wh_nav-open::before {
  transform: rotate(-45deg) translate(-8px, 6px);
}

.hamburger.wh_nav-open .bar {
  opacity: 0;
}

.hamburger.wh_nav-open::after {
  transform: rotate(45deg) translate(-9px, -8px);
}

/* *****************************  */

.wh_main-nav-list {
  display: flex;
  list-style: none;
  gap: 1.6rem;
  align-items: center;
}

/* justify-content: center; */

@media (min-width: 1250px) {

.wh_main-nav-list {
    gap: 4.8rem
}
  }

.wh_main-nav-link:link,
.wh_main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: var(--color-bg);
  font-weight: 500;
  font-size: 2.4rem;
  transition: all 0.3s;
}

.wh_main-nav-link:hover,
.wh_main-nav-link:active {
  color: var(--color-4);
}

.wh_main-nav-link.wh_nav-cta:link,
.wh_main-nav-link.wh_nav-cta:visited {
  padding: 1rem 2rem;
  border-radius: 9px;
  color: var(--color-bg);
  background-color: var(--color-3);
}

.wh_main-nav-link.wh_nav-cta:hover,
.wh_nav-cta.wh_nav-cta:active {
  background-color: var(--color-4) !important;
}

/* MOBILE NAVIGATION*/

/*  we use max width here instead of mobile first */

@media (max-width: 59em) {
  /* .wh-btn-mobile-nav {
    display: block;
    z-index: 10;
  } */
  .wh_main-nav {
    background-color: rgb(255, 255, 255, 1);
    position: absolute;
    top: 0;
    left: 0; /*  set left or right for direction of menu entry */
    /* ////////////////  */
    /* width: 100%;   */
    width: 0%;
    overflow: hidden;
    /* transform: translateX(100%); */ /* slide menu in */
    /* ////////////////  */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    /* Hide navigation */
    /* display: none; */
    transition: all 0.5s ease-in;
    opacity: 0%;
    pointer-events: none;
    visibility: hidden;
  }

  .hamburger {
    display: block;
    z-index: 10;
  }

  .wh_nav-open .wh_main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    width: 100%;
    /* transform: translateX(0); */
  }

  /* .wh_nav-open .icon-mobile-nav[name='close-outline'] {
    display: block;
  }
  .wh_nav-open .icon-mobile-nav[name='menu-outline'] {
    display: none;
  } */

  .wh_main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .wh_main-nav-link:link,
  .wh_main-nav-link:visited {
    font-size: 3rem;
    color: var(--color-grey);
  }

  .wh_main-nav-link:hover,
  .wh_main-nav-link:active {
    color: var(--color-4);
  }
}

/* ******************* */

/* HERO SECTION        */

/* ******************* */

.wh_section-hero {
  background-color: #fdf2e9;
  padding: 4.8rem 0 9.6rem 0;
}

.wh_hero {
  width: 99%; /* or add padding */
  max-width: 120rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 6.4rem;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 1rem;
}

@media (min-width: 1500px) {

.wh_hero {
    max-width: 130rem
}
  }

@media (min-width: 1250px) {

.wh_hero {
    gap: 9.6rem
}
  }

@media (min-width: 800px) {

.wh_hero {
    /* @media (min-width: 1200px) { */
    grid-template-columns: 1fr 1fr;
    padding: 0 3.2rem;
    gap: 4.8rem
}
  }

.wh-hero__description {
    font-size: 2.6rem !important;
    line-height: 1.4 !important;
  }

.wh-hero__tag {
    font-size: 3.1rem !important;
    line-height: 1.4 !important;
  }

/* ///////////////////////    */

.wh_cuts {
  /* padding: 0 0 0 0; */
  background: var(--color-1);
  font-size: 20rem;
}

.wh_cuts_plain {
  /* padding: 0 0 0 0; */

  font-size: 20rem;
  background-color: var(--color-bg);
}

.wh_cuts--plain-large {
  /* padding: 0 0 0 0; */
  display: block;
  font-size: 20rem;
  background-color: var(--color-bg);
  height: 18vh;
  width: 100%;
}

/* ******************  */

/* West Hill Image background section */

.wh-page-section {
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;

  position: relative;
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wh-page-section--bg-1 {
    background: linear-gradient(
        to right bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      url('assets/images/whm/pic1.jpg') no-repeat center center/ cover;

    /* background-image: linear-gradient(to right bottom, rgba(195, 245, 247, 0.8), rgba(28, 112, 145, 0.8)), url(https://source.unsplash.com/random); */

    /* mix-blend-mode: darken; */
    /* height: 50vh; */
    height: 40rem;
  }

.wh-page-section--bg-2 {
    background: linear-gradient(
        to right bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      url('assets/images/whm/pic4.jpg') no-repeat center center/ cover;
    height: 40rem;
  }

.wh-page-section--bg-3 {
    /* background-image: url('assets/images/whm/whm-banner3.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; */
    /* height: 55vh; */
    height: 40rem;

    background: linear-gradient(
        to right bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      url('assets/images/whm/pic5.jpg') no-repeat center center/ cover;
  }

/*   WH Footer */

.wh_logo {
  height: 9rem;
}

.wh_logo--champ {
    filter: invert(15%) sepia(8%) saturate(1173%) hue-rotate(314deg)
      brightness(111%) contrast(76%);
  }

.wh_logo-1 {
  width: 18rem !important;
}

.wh_icons {
  display: flex;
  flex: 0 1 auto;
  margin: 0 auto;
  max-width: 800px;
  width: 90%;
  padding: 1rem 0 3rem 0;
  justify-content: center;
  align-items: center;
}

.wh_icons > a > img {
    /* width: 8rem; */
    height: 7rem;
  }

.wh-footer {
  /* background-color: $color-grey-dark-3; */
  background-color: var(--color-1) !important;
  /* padding: 2rem 0; */
  font-size: 1.4rem;
  color: #f7f7f7;
}

.wh-footer img {
    display: inline;
    width: 30rem;
    padding-top: 4rem;
  }

.wh-footer {

  @include respond(tab-port) {
    padding: 8rem 0;
  }
}

.wh-footer__logo-box {
    text-align: center;
    margin-bottom: 1rem;
  }

@media (min-width: 800px) {

.wh-footer__logo-box {
    /* @media (min-width: 1200px) { */
      /* margin-bottom: 8rem; */
  }
  }

/* &__logo {
    width: 5rem;
    height: auto;
  } */

.wh-footer__navigation {
    border-top: 1px solid #777;
    /* padding: 2rem 0; */
    display: inline-block;

    @include respond(tab-port) {
      width: 100%;
      text-align: center;
    }
  }

.wh-footer__list {
    list-style: none;
  }

.wh-footer__item {
    display: inline-block;
  }

.wh-footer__item:not(:last-child) {
      margin-right: 1.5rem;
    }

.wh-footer__copyright {
    border-top: 1px solid #777;
    /* padding-top: 2rem; */

    @include respond(tab-port) {
      width: 100%;
      float: none;
    }
  }

/* /////////////  */

/* services */

.wh-center-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.wh-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'pic-1'
    'text-1'
    'pic-2'
    'text-2'
    'pic-3'
    'text-3';
}

.wh-grid__pic-1 {
    grid-area: pic-1;
  }

.wh-grid__pic-2 {
    grid-area: pic-2;
  }

.wh-grid__pic-3 {
    grid-area: pic-3;
  }

.wh-grid__text-1 {
    grid-area: text-1;
  }

.wh-grid__text-2 {
    grid-area: text-2;
  }

.wh-grid__text-3 {
    grid-area: text-3;
  }

@media (min-width: 800px) {

.wh-grid {
    /* @media (min-width: 1200px) { */
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas:
      'pic-1 pic-1 text-1 text-1 text-1'
      'text-2 text-2 text-2 pic-2  pic-2'
      'pic-3 pic-3 text-3 text-3 text-3';
  }
  }

.wh-grid-h {
  font-size: 4.4rem !important;
  line-height: 1 !important;
  font-weight: 200 !important;
  letter-spacing: -3px !important;
  padding: 1rem 0 !important;
}

.wh-grid-para {
  font-size: 2.4rem !important;
  line-height: 1.2 !important;
  font-weight: 200 !important;
  letter-spacing: 1px !important;
  padding: 1rem 0 !important;
  color: inherit !important;
  padding: 3rem 0 6rem !important;
}

@media (min-width: 530px) {

.wh-grid-para {
    /* @media (min-width: 620px) {  */
    padding: 3rem 0 6rem !important
}
  }

@media (min-width: 800px) {

.wh-grid-para {
    /* @media (min-width: 1200px) { */
    padding: 1rem 0 2rem !important
}
  }

/* section Sign up */

.wh-section--sign-up {
  /* background-color: #37383d; */
  background-color: #606c38;
  border-top: none;
  border-bottom: 1px solid #444;
  text-align: center;
  padding: 10rem 3rem;
}

.wh-section--sign-up .btn {
  font-size: 1.9rem;
  padding: 2rem 5rem;
}

.wh-section--sign-up .section__title {
  margin-bottom: 6rem;
}

.wh-button {
  display: inline-block;
  background-color: var(--color-3);
  color: white;
  font-size: 1.6rem;
  font-family: inherit;
  font-weight: 500;
  border: none;
  padding: 1.5rem 4.5rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: all 0.3s;
}

/* ***** Modal ***** */

/* MODAL WINDOW */

.body-no-scroll {
  overflow: hidden;
}

.wh-modal {
  width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80rem;
  background-color: #f3f3f3;
  padding: 4rem 5rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.3);
  z-index: 10;
  transition: all 0.5s;
}

.wh-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 8;
  transition: all 0.5s;
}

.wh-modal__header {
  font-size: 3.25rem;
  margin-bottom: 4.5rem;
  line-height: 1.5;
}

.wh-modal__form {
  /* margin: 0 2rem; */
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 1rem;
}

@media (min-width: 530px) {

.wh-modal__form {
    /* @media (min-width: 620px) {  */
    grid-template-columns: auto 1fr
}
  }

.wh-modal__form label {
  font-size: 1.7rem;
  font-weight: 500;
  display: none;
}

@media (min-width: 530px) {

.wh-modal__form label {
    /* @media (min-width: 620px) {  */
    display: block
}
  }

.wh-modal__form input,
.wh-modal__form textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  /* border: none; */
  /* outline: none; */
  background: none;

  font-size: 1.7rem;
  padding: 1rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;

  resize: none;
}

.wh-modal__form input::-moz-placeholder, .wh-modal__form textarea::-moz-placeholder {
    color: #aaa;
  }

.wh-modal__form input::placeholder, .wh-modal__form textarea::placeholder {
    color: #aaa;
  }

.wh-modal__form button {
  justify-self: center;
  margin-top: 1rem;
}

@media (min-width: 530px) {

.wh-modal__form button {
    /* @media (min-width: 620px) {  */
    grid-column: 1 / span 2
}
  }

.wh-btn--close-modal {
  font-family: inherit;
  color: inherit;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 4rem;
  cursor: pointer;
  border: none;
  background: none;
}

.wh-hidden {
  visibility: hidden;
  opacity: 0;
}

.wh-highlight {
  position: relative;
}

.wh-highlight::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.7;
  transform: scale(1.07, 1.05) skewX(-15deg);
  background-image: var(--gradient-primary);
}

.wh-btn {
  display: inline-block;
  background-color: var(--color-2);
  font-size: 1.6rem;
  font-family: inherit;
  font-weight: 500;
  border: none;
  padding: 1.25rem 4.5rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
}

.wh-btn:hover {
  background-color: var(--color-1);
}

.btn--text {
  display: inline-block;
  background: none;
  font-size: 1.7rem;
  font-family: inherit;
  font-weight: 500;
  color: var(--color-2);
  border: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.wh-light-green {
  color: var(--color-2);
}

.wh-dark-green {
  color: var(--color-1) !important;
}

/*////////  Bankist //////// */

/* @import 'bankist/_bankist'; */

